<template>
  <div class="commentBlock" v-loading="loadDisabled">
    <div class="avatarBlock">
      <Avatar
        :providerId="comment.reviewerId"
        :international="
          comment.reviewerCountry && comment.reviewerCountry !== ''
            ? Number(comment.reviewerCountry)
            : 0
        "
        :path="comment.userAvatar"
        :popover="true"
      >
      </Avatar>
    </div>
    <div class="commentInfo">
      <div class="mainCommentBox">
        <div class="commentAuthor" @click.stop="clickThisUser">
          {{ comment.reviewer }}
        </div>
        <div class="commentContent">
          {{ commentContent }}
        </div>
        <CommentFooter
          :comment="comment"
          @replyToThisPerson="replyToThisPerson"
          :type="type"
        ></CommentFooter>
      </div>
      <div
        class="viceCommentBox"
        v-show="comment.children && comment.children.length != 0"
        v-infinite-scroll="loadViceCommentData"
        infinite-scroll-disabled="loadDisabled"
        infinite-scroll-distance="10"
        infinite-scroll-immediate-check="false"
      >
        <transition-group name="list" tag="div">
          <div
            class="viceCommentItem"
            v-for="(item, index) in comment.children"
            :key="item.reviewId"
          >
            <ViceComment
              :viceComment="item"
              @replyToThisPerson="replyToThisPerson"
              :type="type"
            ></ViceComment>
          </div>
        </transition-group>
      </div>
      <transition name="fade">
        <CommentInputBox
          v-if="showTheInputBox"
          class="commentInputBox"
          :answerSomeone="answerSomeone"
          :showAvatar="showAvatar"
          @deleteAnswerSomeone="deleteAnswerSomeone"
          @triggerSubmit="triggerSubmit"
        >
        </CommentInputBox>
      </transition>
    </div>
  </div>
</template>
<script>
import Avatar from "../avatarDisplay";
import ViceComment from "./viceComment";
import CommentInputBox from "./commentInputBox";
import CommentFooter from "./commentFooter";
import { getChildComments } from "@/api/newVersion/postContentDetails";
import { addNewReview } from "@/api/newVersion";
export default {
  components: {
    Avatar,
    ViceComment,
    CommentInputBox,
    CommentFooter,
  },
  props: {
    comment: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 是否可以在评论里面回复
    replyInTheComponent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // 处理需要展示的评论内容
    commentContent() {
      return this.reconvert(this.comment.content);
    },
    // 点赞类型
    type() {
      if (this.comment.getCommentsParam) {
        return this.comment.getCommentsParam.type;
      } else {
        return 1;
      }
    },
  },
  watch: {
    "comment.reviewId": {
      handler(val, old) {
        // 监听评论数据变化
        if (val !== old) {
          this.comment.children = [];
          this.lastReviewId = "";
          this.inReplyComment = null;
          this.answerSomeone = "";
          this.$nextTick(() => {
            this.getChildComments();
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      // 加载新数据的状态
      loadDisabled: false,
      // 评论输入框是否展示自己的头像
      showAvatar: true,
      // 要回复的人的信息
      answerSomeone: "",
      // 展示输入框
      showTheInputBox: false,
      // 最后一条子评论的ID
      lastReviewId: "",
      // 当前正在回复的评论
      inReplyComment: null,
    };
  },
  methods: {
    // 回复评论触发
    async triggerSubmit(param) {
      let params = {
        operation: this.comment.getCommentsParam.operation,
        reply: param.commentText,
        reviewId: this.inReplyComment.reviewId,
      };
      if (param.imgUrl) {
        params.imgUrl = param.imgUrl;
      }
      // 关闭输入框
      this.deleteAnswerSomeone();
      let result = await addNewReview(this.comment.getCommentsParam.id, params);
      if (result.code === 200) {
        // 清空最后一个评论ID记录
        this.lastReviewId = "";
        // 清空列表
        this.comment.children = [];
        this.$nextTick(async () => {
          await this.loadViceCommentData();
          this.$store.commit("setNeedRefreshComment", this.comment.reviewId);
        });
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 加载更多数据
    async loadViceCommentData() {
      this.loadDisabled = true;
      await this.getChildComments();
      this.loadDisabled = false;
    },
    // 初始化获取子评论列表
    getChildComments() {
      return new Promise(async (resolve, reject) => {
        if (!this.comment.getCommentsParam) {
          return false;
        }
        let params = {
          operation: this.comment.getCommentsParam.operation,
          childrenSize: 20,
          lastReviewId: this.lastReviewId,
        };
        let result = await getChildComments(this.comment.reviewId, params);
        if (result.code === 200) {
          // 加载列表
          this.comment.children = this.comment.children.concat(
            result.data.data
          );
          // 将最后的一个评论ID存储起来
          this.lastReviewId = this.comment.children[
            this.comment.children.length - 1
          ].reviewId;
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
        this.$nextTick(() => {
          resolve("success");
        });
      });
    },
    // 删除要回复的人(也是关闭输入框的方法)
    deleteAnswerSomeone() {
      this.showTheInputBox = false;
      this.$nextTick(() => {
        this.inReplyComment = null;
        this.answerSomeone = "";
      });
    },
    // 点击回复这个用户
    replyToThisPerson(comment) {
      if (!this.replyInTheComponent) {
        // 将评论设置的参数带到外层组件去
        let handleComment = _.cloneDeep(comment);
        handleComment.getCommentsParam = this.comment.getCommentsParam;
        this.$emit("replyMessageReceived", handleComment);
      } else {
        this.showTheInputBox = true;
        this.$nextTick(() => {
          this.inReplyComment = comment;
          // 配置这条评论的相关信息
          this.answerSomeone = comment.reviewer;
        });
      }
    },
    // 将Unicode转化为评论
    reconvert(str) {
      try {
        str = str.replace(/(\\u)(\w{1,4})/gi, function($0) {
          return String.fromCharCode(
            parseInt(escape($0).replace(/(%5Cu)(\w{1,4})/g, "$2"), 16)
          );
        });
        str = str.replace(/(&#x)(\w{1,4});/gi, function($0) {
          return String.fromCharCode(
            parseInt(escape($0).replace(/(%26%23x)(\w{1,4})(%3B)/g, "$2"), 16)
          );
        });
        str = str.replace(/(&#)(\d{1,6});/gi, function($0) {
          return String.fromCharCode(
            parseInt(escape($0).replace(/(%26%23)(\d{1,6})(%3B)/g, "$2"))
          );
        });
      } catch (error) {}
      return str;
    },
    // 将评论转化为Unicode
    ascii(str) {
      try {
        let value = "";
        for (var i = 0; i < str.length; i++) {
          value +=
            "&#x" + left_zero_4(parseInt(str.charCodeAt(i)).toString(16)) + ";";
        }
        return value;
      } catch (error) {
        return str;
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.list-enter-active, .list-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.list-enter, .list-leave-to {
  opacity: 0;
  transform: translate3d(30px, 0, 0);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translate3d(0, 30px, 0);
}

.commentBlock {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  .avatarBlock {
    height: 40px;
    width: 40px;
    flex-shrink: 0;
    overflow: hidden;
  }

  .commentInfo {
    height: 100%;
    flex: 1;
    min-width: 0;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
    padding-left: 13px;

    .mainCommentBox {
      width: 100%;
      min-height: 0;
      overflow: hidden;
      flex-shrink: 0;

      .commentAuthor {
        width: 100%;
        height: 20px;
        color: #373737;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        text-align: left;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .commentContent {
        width: 100%;
        margin-top: 5px;
        min-height: 20px;
        color: #333333;
        font-size: 14px;
        line-height: 20px;
        word-break: break-all;
      }
    }

    .viceCommentBox {
      flex: 1;
      width: 100%;
      min-height: 0;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        box-shadow: inset 0 0 5px rgba(230, 230, 230, 0.2);
        background: rgba(230, 230, 230, 1);
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
        border-radius: 0;
        background: rgba(0, 0, 0, 0);
      }

      .viceCommentItem {
        height: auto;
        width: 100%;
        box-sizing: border-box;
        padding: 0 15px;
      }

      .moreComment {
        color: #373737;
        font-size: 14px;
        line-height: 24px;
        text-align: left;

        span {
          cursor: pointer;
        }

        .iconStyle {
          margin-left: 7px;
          color: #333333;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }

    .commentInputBox {
      flex-shrink: 0;
      padding: 15px 0;
      width: 100%;
    }
  }
}
</style>
