var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loadDisabled,
          expression: "loadDisabled"
        }
      ],
      staticClass: "commentBlock"
    },
    [
      _c(
        "div",
        { staticClass: "avatarBlock" },
        [
          _c("Avatar", {
            attrs: {
              providerId: _vm.comment.reviewerId,
              international:
                _vm.comment.reviewerCountry &&
                _vm.comment.reviewerCountry !== ""
                  ? Number(_vm.comment.reviewerCountry)
                  : 0,
              path: _vm.comment.userAvatar,
              popover: true
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "commentInfo" },
        [
          _c(
            "div",
            { staticClass: "mainCommentBox" },
            [
              _c(
                "div",
                {
                  staticClass: "commentAuthor",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.clickThisUser.apply(null, arguments)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.comment.reviewer) + "\n      "
                  )
                ]
              ),
              _c("div", { staticClass: "commentContent" }, [
                _vm._v("\n        " + _vm._s(_vm.commentContent) + "\n      ")
              ]),
              _c("CommentFooter", {
                attrs: { comment: _vm.comment, type: _vm.type },
                on: { replyToThisPerson: _vm.replyToThisPerson }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.comment.children && _vm.comment.children.length != 0,
                  expression: "comment.children && comment.children.length != 0"
                },
                {
                  name: "infinite-scroll",
                  rawName: "v-infinite-scroll",
                  value: _vm.loadViceCommentData,
                  expression: "loadViceCommentData"
                }
              ],
              staticClass: "viceCommentBox",
              attrs: {
                "infinite-scroll-disabled": "loadDisabled",
                "infinite-scroll-distance": "10",
                "infinite-scroll-immediate-check": "false"
              }
            },
            [
              _c(
                "transition-group",
                { attrs: { name: "list", tag: "div" } },
                _vm._l(_vm.comment.children, function(item, index) {
                  return _c(
                    "div",
                    { key: item.reviewId, staticClass: "viceCommentItem" },
                    [
                      _c("ViceComment", {
                        attrs: { viceComment: item, type: _vm.type },
                        on: { replyToThisPerson: _vm.replyToThisPerson }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          ),
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _vm.showTheInputBox
                ? _c("CommentInputBox", {
                    staticClass: "commentInputBox",
                    attrs: {
                      answerSomeone: _vm.answerSomeone,
                      showAvatar: _vm.showAvatar
                    },
                    on: {
                      deleteAnswerSomeone: _vm.deleteAnswerSomeone,
                      triggerSubmit: _vm.triggerSubmit
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }